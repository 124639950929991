<template>
    <div>
        <!--banner on-->
        <div>
            <el-carousel id="banner-carousel" arrow="always">
                <el-carousel-item v-for="(item, index) in banner" :key="index">
                    <img :src="item" class="banner-carousel-img">
                </el-carousel-item>
            </el-carousel>
        </div>
        <!--banner off-->

        <div class="is-main">
            <div class="container" v-if="flag">
                <div class="m-work f-mb20">
                    <!-- 去掉文章标题 -->
                    <!-- <a target="_blank" class="u-workt f-db" v-if="recommendArticle.length > 0">
                        {{ recommendArticle[0].title }}
                    </a> -->

                    <div class="u-workc row f-clearfix">

<!--                     // 用于展示不同文章或产品的轮播图，目前是改为轮播首页推荐分类的头图
                        <div class="u-workl col-md-6" style="padding-top: 1.5em;">
                            <el-carousel id="content-carousel" arrow="always" v-if="recommendArticle.length > 1">
                                <el-carousel-item :key="index" :label="index + 1" v-for="(item, index) in recommendArticle">
                                    <a>
                                        <img class="content-carousel-img" :src="item.header" alt="">
                                    </a>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                         -->

                        <div class="u-workl col-md-6" style="padding-top: 1.5em;">
                            <el-carousel id="content-carousel" arrow="always" v-if="articleList.length > 1">
                                <el-carousel-item :key="index" :label="index + 1" v-for="(item, index) in articleList">
                                    <a>
                                        <img class="content-carousel-img" :src="item.header" alt="">
                                    </a>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="u-workr f-md-ha col-md-6" style="padding-top: 1.5em;">
                            <div class="u-workrt">
                                <ul class="f-clearfix">
                                    <li v-for="(recommendItem, recommendIndex) in recommendCate" :key="recommendIndex" v-if=" recommendIndex < 4" @click="handleRecommend(recommendIndex)">
                                        <a target="_blank" class="f-db" :class="recommendIndex == activeIndex ? 'active' : '' ">{{ recommendItem.name }}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="u-workroutc">
                                <div class="u-workrc">
                                    <!-- 用于控制显示多少条文章 v-if="articleIndex < 4" -->
                                    <div class="u-workrclist f-clearfix" v-for="(articleItem, articleIndex) in articleList" v-if="articleIndex < 4" @click="handeleNavigate(articleItem)">
                                        <div class="u-time f-fl">
                                            <p>{{ formDay(articleItem.createTime) }}</p>
                                            <p>{{ formDate(articleItem.createTime) }}</p>
                                        </div>
                                        <div class="u-workrc1 f-fl">
                                            <a target="_blank">{{ articleItem.title }}</a>
                                            <p>{{ articleItem.description }}</p>
                                        </div>
                                        <div class="u-more f-fr">
                                            <a target="_blank" class="f-db"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 首页右边 -->
                        <!-- <div class="u-workr f-md-ha col-md-4">
                            <div class="u-workrt">
                                <ul class="f-clearfix">
                                    <li v-for="(recommendItem, recommendIndex) in recommendCate" :key="recommendIndex" v-if=" recommendIndex < 4" @click="handleRecommend(recommendIndex)">
                                        <a target="_blank" class="f-db" :class="recommendIndex == activeIndex ? 'active' : '' ">{{ recommendItem.name }}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="u-workroutc">
                                <div class="u-workrc">
                                    <div class="u-workrclist f-clearfix" v-for="(articleItem, articleIndex) in articleList" v-if="articleIndex < 4" @click="handeleNavigate(articleItem)">
                                        <div class="u-time f-fl">
                                            <p>{{ formDay(articleItem.createTime) }}</p>
                                            <p>{{ formDate(articleItem.createTime) }}</p>
                                        </div>
                                        <div class="u-workrc1 f-fl">
                                            <a target="_blank">{{ articleItem.title }}</a>
                                            <p>{{ articleItem.description }}</p>
                                        </div>
                                        <div class="u-more f-fr">
                                            <a target="_blank" class="f-db"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->



                    </div>
                </div>


                <div class="m-adv f-mb20 visible-lg visible-md c-shine f-pr f-oh">
                    <img src="@/assets/images/20210108090736.EPUgNvaerTyG.png" alt="">
                </div>

                <div class="m-listhome row" v-if="parityFlag">
                    <div class="col-md-6 f-mb20" v-for="(normalItem, normalIndex) in normalCate" :key="normalIndex">
                        <div class="u-listhome f-md-ha">
                            <div class="u-listhomet f-clearfix f-mb10">
                                <a class="u-listhometl f-db f-fl">{{ normalItem.name }}</a>
                                <a class="u-listhometr f-db f-fr" @click="handleNavigate(normalItem, -1)">MORE+</a>
                            </div>
                            <div class="u-listhomec">
                                <ul>
                                    <li class="f-clearfix" v-for="(articleItem, articleIndex) in normalItem.articleList" :key="articleIndex" @click="handeleNavigate(articleItem)">
                                        <a :title="articleItem.title" target="_blank" class="f-db f-fl">
                                            {{ articleItem.title }}
                                        </a>
                                        <span class="f-fr">{{ formDate(articleItem.createTime) }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-listhome row" v-if="!parityFlag">
                    <div class="col-md-6 f-mb20" v-for="(normalItem, normalIndex) in normalCate" :key="normalIndex" v-if="normalIndex < normalCate.length - 1">
                        <div class="u-listhome f-md-ha">
                            <div class="u-listhomet f-clearfix f-mb10">
                                <a class="u-listhometl f-db f-fl">{{ normalItem.name }}</a>
                                <a class="u-listhometr f-db f-fr" @click="handleNavigate(normalItem, -1)">MORE+</a>
                            </div>
                            <div class="u-listhomec">
                                <ul>
                                    <li class="f-clearfix" v-for="(articleItem, articleIndex) in normalItem.articleList" :key="articleIndex" @click="handeleNavigate(articleItem)">
                                        <a :title="articleItem.title" target="_blank" class="f-db f-fl">
                                            {{ articleItem.title }}
                                        </a>
                                        <span class="f-fr">{{ formDate(articleItem.createTime) }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- 最底部 仅展示首图 -->
                <!-- <div class="m-jianshe f-mb20 f-pr f-oh" v-if="!parityFlag"> -->
                <div class="m-jianshe f-mb20 f-pr f-oh" >
                    <div style="margin-bottom: 2em;" v-for="(recommendCateItem, recommendCateIndex) in allRecommendCateProduct" :key="recommendCateIndex">
                        <div class="u-listhomet f-clearfix f-mb10">
                            <a class="u-listhometl f-db f-fl">{{ recommendCateItem.name }}</a>
                            <a class="u-listhometr f-db f-fr" @click="handleNavigate(recommendCateItem, -1)">MORE+</a>
                        </div>

                        <div class="u-jianshe">
                            <ul class="f-clearfix">
                                <li v-for="(productItem, productIndex) in recommendCateItem.productList" :key="productIndex" v-if="productIndex < 10" @click="handeleProductNavigate(productItem)">
                                    <a :title="lastNormalCateProduct" target="_blank" class="f-db">
                                        <div class="u-jiansheimg">
                                            <img class="footer-img" :src="productItem.header" alt="">
                                        </div>
                                    <span>{{ productItem.productName }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>


<!--  
                    <div class="u-jianshe">
                        <ul class="f-clearfix">
                            <li v-for="(productItem, productIndex) in lastNormalCateProduct.productList" :key="productIndex" v-if="productIndex < 10" @click="handeleProductNavigate(productItem)">
                                <a :title="lastNormalCateProduct" target="_blank" class="f-db">
                                    <div class="u-jiansheimg">
                                        <img class="footer-img" :src="productItem.header" alt="">
                                    </div>
                                <span>{{ productItem.productName }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
 -->

                </div>


<!-- 
                <div class="m-jianshe f-mb20 f-pr f-oh" v-if="!parityFlag">
                    <div class="u-listhomet f-clearfix f-mb10">
                        <a class="u-listhometl f-db f-fl">{{ lastNormalCate.name }}</a>
                        <a class="u-listhometr f-db f-fr" @click="handleNavigate(lastNormalCate, -1)">MORE+</a>
                    </div>
                    <div class="u-jianshe">
                        <ul class="f-clearfix">
                            <li v-for="(articleItem, articleIndex) in lastNormalCate.articleList" :key="articleIndex" v-if="articleIndex < 2" @click="handeleNavigate(articleItem)">
                                <a :title="lastNormalCate" target="_blank" class="f-db">
                                    <div class="u-jiansheimg">
                                        <img :src="articleItem.header" alt="">
                                    </div>
                                <p>{{ articleItem.title }}</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                 -->

            </div>
        </div>
    </div>
</template>

<script>
import '../assets/js/qrcode.min.js'

import * as api from '@/api/api'

export default {
    name: "index",
    data() {
        return {
            companyInfo: {},
            articleList: [],
            recommendCate: [],
            normalCate: [],
            normalCateProduct: [],
            allRecommendCateProduct: [],
            recommendArticle: [],
            flag: false,
            activeIndex: 0,
            parityFlag: true,   // 默认偶数
            lastNormalCate: {},
            lastNormalCateProduct: {},
        }
    },
    created() {

        let meta = document.createElement("meta")
        meta.id = 'index'
        this.companyInfo = this.$store.state.siteInfo
        meta.content = 'sbmklsb'

        document.title = '首页'
        api.indexData({siteId: this.$store.getters.siteId}).then(res => {
            // console.log("res: " + JSON.stringify(res))
            this.recommendArticle = res.recommendArticle
            this.normalCate = res.normalCate
            this.normalCateProduct = res.normalCateProduct
            this.recommendCateProduct = res.recommendCateProduct
            // 暂时去掉，不然最后的产品展示不显示 2022年7月22日
            // if((this.normalCate.length % 2) !== 0) {
                this.parityFlag = false
                this.lastNormalCate = this.normalCate[this.normalCate.length - 1]
                this.allRecommendCateProduct = this.recommendCateProduct

                // console.log("normalCateProduct: {}" + JSON.stringify(this.normalCateProduct))
                // console.log("lastNormalCateProduct: {}" + JSON.stringify(this.lastNormalCateProduct))
                console.log("allRecommendCateProduct: " + JSON.stringify(this.allRecommendCateProduct))
            // }

            this.recommendCate = res.recommendCate
            if(this.recommendCate.length === 0) {
                this.articleList = []
            } else {
                this.articleList = this.recommendCate[0].articleList
            }

            
            console.log("recommendCate: " + JSON.stringify(this.recommendCate))

            this.flag = true
        })
    },
    methods: {
        handleRecommend(recommendIndex) {
            this.activeIndex = recommendIndex
            this.articleList = this.recommendCate[recommendIndex].articleList
            window.console.log("recommendCate: " + this.recommendCate)
            window.console.log("this.recommendCate[0].articleList: " + this.recommendCate[0].articleList)
        },
        handeleNavigate(item) {
            this.$router.push({
                path: `/detail/${item.id}`
            })
        },
        
        handeleProductNavigate(item) {
            this.$router.push({ 
                path: `/productDetail/${item.id}`
            })
        },

        handleNavigate(item, index) {
            this.$store.commit('SET_ACTIVEIDDEX', index)
            this.$store.commit('SET_ACTIVEID', item.id)
            this.$router.push({
                path: `/channel/${item.id}`,
            })
        }
    },
    computed: {
        formDate() {    // JS 不同时间格式转换(ISO时间&时间戳转北京时间)
            return date => {

                return date.substr(0,10)
            }
        },
        formDay() {
            return day => {
                let dt = new Date(day)
                dt = dt.getDate()
                return dt < 10 ? '0' + dt : dt;
            }
        },
        banner() {
            return this.$store.getters.siteInfo.banner
        }
    }
}
</script>

<style lang="less"  scoped>

@aaa: ~'>>>';

.m-jianshe{height: 100%;}

.banner-carousel-img, .content-carousel-img{
    height: 100%;
    width: 100%;
}


#banner-carousel {
    height: 100%;
}

#content-carousel {
    height: 100%;
}


.active {
    color: #005B84;
}


/*small xs*/
@media screen and (max-width: 638px) {
    .banner-carousel-img {
        height: 40%;
        width: 100%;
    }

    .content-carousel-img{
        height: 70%;
        width: 100%;
    }

    #banner-carousel {
        margin-top: 1em;
        height: 100px;
    }

    #content-carousel {
        height: 200px;
    }

    .u-workl {
        height: 200px;
    }

    #content-carousel @{aaa} .el-carousel__container {
        height: 200px;
    }

    .footer-img {
        height: 100%;
        width: 100%;
    }

}

</style>
