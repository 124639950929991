<template>
    <div>
        <div class="is-main f-mb15">
            <div class="container">
                <section class="m-location f-mb10">
                    <span>当前位置：</span>
                    <a href="/">网站首页</a> &gt;
                    <a href="/content/channel/4fe009923db32aab1c7b23c7/">产品详情</a>
                </section>

                <div class="m-pgpdbox1">
                    <div class="m-detailbox">
                        <h1 class="u-lgtit text-center f-mb15 f-md-mb10">{{ cateInfo.name }}</h1>
                        <div class="m-dtfuns f-clearfix">
                            <div class="u-wzinfo f-fl f-md-fn">
                                <span>发布时间：{{ productDetailInfo.publishTime }}</span>
                                <span>发布人：{{ productDetailInfo.createBy }}</span>
                                <!-- <span>浏览：{{ productDetail.views }} 次</span> -->
                            </div>

                        </div>

                        <!-- <div class="m-dtfonts">
                            <div class="m-dttexts f-clearfix j-fontContent" id="zoom" v-html="productDetail.detail"></div>
                        </div> -->

                        <!-- 仅展示首图 -->
                        <!-- <div class="m-jianshe f-mb20 f-pr f-oh" v-if="!parityFlag"> -->
                        <div class="m-jianshe f-mb20 f-pr f-oh" >
                            <!-- <div class="u-listhomet f-clearfix f-mb10">
                                <a class="u-listhometl f-db f-fl">{{ lastNormalCateProduct.name }}</a>
                                <a class="u-listhometr f-db f-fr" @click="handleNavigate(lastNormalCateProduct, -1)">MORE+</a>
                            </div> -->
                            <div class="u-jianshe">
                                <ul class="f-clearfix">
                                    <li v-for="(productItem, productIndex) in productDetailInfo.files" :key="productIndex" v-if="productIndex < 10">
                                        <a :title="productItem" target="_blank" class="f-db">
                                            <div class="u-jiansheimg">
                                                <img class="footer-img" :src="productItem" alt="">
                                            </div>
                                        <!-- <span>{{ getFilesName(productItem) }}</span> -->
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <div class="m-dtdownload f-mb15">
                            <ul>
                            </ul>
                        </div>
                        <div class="m-btfuns f-clearfix f-mb15">
                            <ul class="f-fr">
                                <li class="u-gotp j-goTop">
                                    <a :href="item" target="_blank" v-for="(item, index) in productDetailInfo.files" :key="index">
                                        {{ '附件' + getFilesName(item) }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="m-dtsxqh f-clearfix">
                            <ul>
                                <li class="f-fl u-dtprev" @click="handlePrev">
                                    上一条：
                                    <a v-if="prev != null">{{ prev.title }}</a>
                                    <a v-else>没有上一条了</a>
                                </li>
                                <li class="f-fr u-dtnext" @click="handleNext">
                                    下一条：
                                    <a v-if="next != null">{{ next.title }}</a>
                                    <a v-else>没有下一条了</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as api from '@/api/api'

export default {
    name: "productDetail",
    data() {
        return {
            productDetailInfo: {},
            next: {},
            prev: {},
            cateInfo: {},
            childList: [],
            cateTree: [],

        }
    },
    created() {
        this.activeId = this.$route.params.id
        this.getProductDetail(this.$route.params.id)
        // this.getCurrentCate()
    },

    methods: {

        getProductDetail(id) {
            api.productItem({ productId: id }).then(res => {

                let head = document.getElementsByTagName("head")    // 修改meta
                let meta = document.createElement("meta")
                meta.content = res.description

                if(this.$store.state.meta != null) {
                    head[0].replaceChild(meta, this.$store.state.meta)
                }else {
                    head[0].appendChild(meta)
                }
                this.$store.state.meta = meta



                document.title =res.productName      // 修改title
                console.log(res)
                this.productDetailInfo = res
                if(this.productDetailInfo.files != null) {
                    this.productDetailInfo.files = JSON.parse(this.productDetailInfo.files)
                }
                this.next = res.next
                this.prev = res.prev

                this.getCurrentCate()
            })

        },


        
        
        getCurrentCate() {
            api.cateTree({siteId: this.$store.getters.siteId}).then(res => {
                window.console.log("res: "+ res)
                // this.cateTree = JSON.stringify(res)
                this.cateTree = res
                window.console.log("this.cateTree: "+ JSON.stringify(this.cateTree))

                console.log("this.productDetailInfo.cateId: "+ JSON.stringify(this.productDetailInfo.cateId))

                this.cateTree.forEach((item, index) => {
                    if(this.productDetailInfo.cateId == parseInt(item.id)) {
                        window.console.log("sssssssssssss")
                        this.cateInfo = item
                    } else {
                        window.console.log("item: " + JSON.stringify(item))
                        if(item.isHaveSubMenu) {
                            window.console.log("tempChild: " + JSON.stringify(item.childs))

                            for(let childItem of item.childs) {
                                window.console.log("childItem: " + JSON.stringify(childItem))

                                if(this.productDetailInfo.cateId == parseInt(childItem.id)) {
                                    this.cateInfo = childItem
                                }
                            }
                        }

                    }
                    window.console.log("this.cateInfo item : "+ JSON.stringify(parseInt(item.id)))
                    window.console.log("this.cateInfo: "+ JSON.stringify(this.cateInfo))
                })
            })

            window.console.log("===========")

            

            // this.$store.getters.cateList.forEach((item, index) => {
            //     if(this.$store.state.activeId === item.id) {
            //         this.cateInfo = this.$store.getters.cateList[index]
            //         document.title = this.cateInfo.name
                    
            //     }
            // })

        },

        handleNext() {
            // this.getProductDetail(this.next.id)
            this.$store.commit('SET_ACTIVEPRODUCTID', this.next.id)
            this.$router.push({
                path: `/productDetail/${this.next.id}`
            })
        },
        handlePrev() {
            // this.getProductDetail(this.prev.id)
            this.$store.commit('SET_ACTIVEPRODUCTID', this.prev.id)
            this.$router.push({
                path: `/productDetail/${this.prev.id}`
            })
        }
    },
    computed: {
        getFilesName() {
            return item => {
                console.log("item: "+ item)
                return item.substring(item.length - 24)
            }
        }
    },
    watch: {
        '$store.state.activeProductId': function(newValue) {
            this.getProductDetail(newValue)
        }
    }
}
</script>

<style scoped>


.m-jianshe {
    margin-top: 1.5em;
    height: 100%;
}


.u-jianshe ul li{width: 380px; margin-right: 10px; float: left;}

.u-jianshe ul li img{height: 280px;}
.u-jiansheimg{height: 280px}

.footer-img {
    height: 100%;
    width: 100%;
}

/*small xs*/
@media screen and (max-width: 638px) {

    .u-jianshe ul li{width: 320px; float: left; margin-bottom: 1em;}

    .u-jianshe ul li img{height: 280px;}
    .u-jiansheimg{height: 280px}
}

</style>
