<template>
    <div>
        <div class="is-main f-mb15">
            <div class="container">
                <section class="m-location f-mb10">
                    <span>当前位置：</span>
                    <a href="/">网站首页</a> &gt;
                    <a >{{ cateInfo.name }}</a>
                </section>
                <div class="m-pgpdbox1">

                    <div class="row f-mlrf10">
                        <section class="col-md-3 f-plr10 m-listlf f-md-mb15">
                            <div class="g-listlf">
                                <h1 class="hidden-sm hidden-xs u-tit">
                                    <span></span>
                                    {{ cateInfo.name }}
                                </h1>
                                <h2 class="visible-sm visible-xs u-tit">{{ cateInfo.name }}</h2>
                                <ul>
                                    <li v-for="(item, index) in childList" :class=" index == activeIndex ? 'u-active' : ''" @click="handleSelectChild(index)">
                                        <a :title="item.name">{{ item.name }}</a>
                                    </li>
                                </ul>
                            </div>
                        </section>

                        <section class="col-md-9 f-plr10 m-listrg">
                            <div class="m-titstyle1 f-clearfix f-mb5" >
                                <span class="u-tit f-fl" v-if="activeIndex < 0">{{ cateInfo.name }}<em></em></span>
                                <span class="u-tit f-fl" v-else>{{ childList[activeIndex].name }}<em></em></span>
                            </div>
                            <div class="m-cglist m-liststyle1 f-md-mb15">
                                <ul>
                                    
                                    <li v-if="cateInfo.type == 0" v-for="(item, index) in cateArticleList" :key="index" @click="handeleNavigate(item, cateInfo.type)">
                                        <span>{{ formDate(item.createTime) }}</span>
                                        <a :title="item.name" target="_blank">{{ item.title }}</a>
                                    </li>

<!-- 
                                    <li v-if="cateInfo.type == 1" v-for="(item, index) in cateProductList" :key="index" @click="handeleNavigate(item, cateInfo.type)">
                                        <span>{{ formDate(item.createTime) }}</span>
                                        <a :title="item.name" target="_blank">
                                            <div class="u-jiansheimg">
                                                <img class="footer-img" :src="item.header" alt="">
                                            </div>
                                        </a>
                                    </li>
 -->

                                </ul>
                            </div>

                            <div class="u-jianshe" v-if="cateInfo.type == 1">
                                <ul class="f-clearfix">
                                    <li  v-for="(item, index) in cateProductList" :key="index" @click="handeleNavigate(item, cateInfo.type)" >
                                        <a :title="productItem" target="_blank" class="f-db">
                                            <div class="u-jiansheimg">
                                                <img class="footer-img" :src="item.header" alt="">
                                            </div>
                                        <!-- <span>{{ getFilesName(productItem) }}</span> -->
                                        </a>
                                    </li>

                                </ul>
                            </div>

                            <div class="text-right m-paginationyun7">
                                <div id="pagination" class="f-fr">
<!--                                    <pagination currentpage="1" pagesize="15" pagecount="1" total="3"></pagination>-->
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as api from '@/api/api'

export default {
    name: "channel",
    data() {
        return {
            cateInfo: {},
            childList: [],
            cateArticleList: [],
            cateProductList: [],
            activeIndex: -1
        }
    },
    created() {
        this.loading()
    },
    methods: {
        loading() {
            this.$store.getters.cateList.forEach((item, index) => {
                if(this.$store.state.activeId === item.id) {
                    this.cateInfo = this.$store.getters.cateList[index]
                    document.title = this.cateInfo.name
                    
                }
            })

            console.log("this.cateInfo: " + JSON.stringify(this.cateInfo))

            this.childList = this.cateInfo.childs
            console.log("this.activeIndex: " + JSON.stringify(this.$store.state.activeIndex))
            console.log("this.activeId: " + JSON.stringify(this.$store.state.activeId))
            if(this.$store.state.activeIndex === -1) {
                api.cateArticle({
                    siteId: this.$store.getters.siteId,
                    pCateId: this.$store.state.activeId,
                    size: 100
                }).then(res => {
                    this.cateArticleList = res
                })

                api.cateProduct({
                    siteId: this.$store.getters.siteId,
                    pCateId: this.$store.state.activeId,
                    size: 100
                }).then(res => {
                    this.cateProductList = res

                    console.log("this.cateProductList: " + JSON.stringify(this.cateProductList))
                })
            }else {
                this.handleSelectChild(this.$store.state.activeIndex)
            }
        },

        handleSelectChild(index) {
            console.log(index)
            console.log('this.childList[index]: ' + this.childList[index])
            this.activeIndex = index
            api.articlePage({
                page: 0,
                pageSize: 100,
                siteId: this.childList[index].siteId,
                cateId: this.childList[index].id
            }).then(res => {
                console.log(res)
                this.cateArticleList = res.content
            })

            api.productPage({
                page: 0,
                pageSize: 100,
                siteId: this.childList[index].siteId,
                cateId: this.childList[index].id
            }).then(res => {
                console.log(res)
                this.cateProductList = res.content
            })
        },

        handeleNavigate(item, index) {
            if( index == 0) {
                this.$router.push({
                    path: `/detail/${item.id}`
                })
            } else if(index == 1) {
                this.$router.push({
                    path: `/productDetail/${item.id}`
                })
            }
        }
    },
    watch: {
        '$store.state.activeId': function(newValue) {
            this.activeIndex = -1
            this.loading()
        }
    },
    computed: {
        formDate() {    // JS 不同时间格式转换(ISO时间&时间戳转北京时间)
            return date => {
                // date.substr(0,10)
                // TODO 2022年7月17日06点53分 换了截取方法，先转字符串后截取，不然报错
                return (date).toString().substr(0,10)
            }
        }
    }
}
</script>

<style scoped>

.u-jianshe {
    margin: 0;
    padding-left: 0.5em;
}

.u-jianshe ul li{
    width: 32.1%; 
    /* margin-left: 0.6em;  */
    /* margin-right: 1em; */
    float: left;
    margin-bottom: 0.6em;
}

.u-jianshe ul li img{
    height: 280px;

}

.u-jiansheimg{
    height: 280px;
}

.footer-img {
    height: 100%;
    width: 100%;
}

/*small xs*/
@media screen and (max-width: 638px) {
    .u-jianshe ul li{
        width: 30.2%; 
        /* margin-left: 0.6em;  */
        /* margin-right: 1em; */
        float: left;
        margin-bottom: 0.6em;
    }

    .u-jiansheimg{
        height: 125px;
    }

    .u-jianshe ul li img{
        height: 150px;
    }

}

</style>
