<template>

    <div id="allDiv">
        <!-- <div id="topText">欢迎留言~</div> -->

    <el-card>
        
        <el-divider>
            <span style="font-size: 1.5em;">留 言 板</span>
        </el-divider>
        <el-form ref="messageForm" id="selectForm" :model="messageForm">
        <!-- <el-form ref="form" :model="articleItem" label-width="100px"> -->
            <el-form-item label="" id="labelHidden"></el-form-item>

            <el-form-item label="姓名/Name：" prop="name">
                <el-input v-model="messageForm.name" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item  label="公司/Company：" prop="company">
                <el-input v-model="messageForm.company" placeholder="请输入您的公司"></el-input>
            </el-form-item>
            <el-form-item  label="地址/Address：" prop="address">
                <el-input v-model="messageForm.address" placeholder="请输入您的地址"></el-input>
            </el-form-item>
            <el-form-item  label="电子邮件/Email：" prop="email">
                <el-input v-model="messageForm.email" placeholder="请输入您的电子邮件"></el-input>
            </el-form-item>
            <el-form-item  label="电话/Phone：" prop="phone">
                <el-input v-model="messageForm.phone" placeholder="请输入您的电话"></el-input>
            </el-form-item>
            <el-form-item  label="传真/Fax：" prop="fox">
                <el-input v-model="messageForm.fox" placeholder="请输入您的传真"></el-input>
            </el-form-item>
            <el-form-item  label="内容/Content：" prop="content">
                <el-input class="message-textArea" v-model="messageForm.content" type="textarea" placeholder="请输入您的留言内容" style="width: 70%;" :autosize="{ minRows: 4, maxRows: 20}">
                
                <!-- <el-input class="message-textArea" v-model="messageForm.content" type="textarea" placeholder="请输入您的留言内容" style="width: 70%;"> -->
                </el-input>
            </el-form-item>

            <el-form-item label="验证码：" prop="verifyCode">
                <span class="svg-container"><svg-icon icon-class="user" /></span>
                <el-input v-model="messageForm.verifyCode" name="verifyCode" type="text" width="200px">
                    <div slot="suffix">
                        <el-image class="code-img" :src="codeImg" fit="cover" @click="getCode"></el-image>
                    </div>
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: center">
                <el-button type="primary" @click="submitForm('messageForm')">提交</el-button>
                <el-button @click="resetForm('messageForm')">重置</el-button>
            </el-form-item>


        </el-form>

        <!-- <image-upload :show="uploadPicFlag" :sign="'header'" :key="new Date() + ((Math.random() * 1000).toFixed(0) + '')" @uploadEmit="uploadEmit"></image-upload>
        <image-upload :show="uploadFilesFlag" :sign="'files'" :key="new Date() + ((Math.random() * 1000).toFixed(0) + '')" @uploadEmit="uploadEmit"></image-upload>
     -->

    </el-card>
    </div>
</template>

<script>
import * as api from '@/api/api'
import * as commonApi from '@/api/common'

// import * as cateApi from '@/api/cate'
// import richText from "@/components/myCommon/richText"
// import ImageUpload from '@/components/ImageUpload'
// import filesTable from "@/views/article/components/filesTable";

export default {
    name: "messageBoard",
    // components: {
    //     richText, ImageUpload, filesTable
    // },
    // props: {
    //     articleItem: {
    //         type: Object,
    //         default: null
    //     },
    //     cateTree: {
    //         type: Array
    //     },
    //     dialogVisible: {
    //         type: Boolean
    //     }
    // },

    data() {
        return {
            messageForm: {
                type: Object,
                siteId: this.$store.getters.siteId,
                name: '',
                company: '',
                address: '',
                email: '',
                phone: '',
                fox: '',
                content: '',
                verifyCode: '',
            },

            // content: '',
            // uploadPicFlag: false,
            // uploadFilesFlag: false
            // cateTree:[]
            verifyCode: '',
            codeImg: '',

        }
    },

    created() {
        this.getCode()
    },

    methods: {

        submitForm(formName) {
            if(this.messageForm.verifyCode !== this.verifyCode) {
                this.$message('验证码错误');
                this.getCode()
                return false
            }

            if (this.messageForm.name === undefined
                || this.messageForm.address === undefined || this.messageForm.address === ''
                || this.messageForm.email === undefined || this.messageForm.email === ''
                || this.messageForm.phone === undefined || this.messageForm.phone === '' 
                || this.messageForm.company === undefined || this.messageForm.company === '' 
                || this.messageForm.fox === undefined || this.messageForm.fox === '' 
                || this.messageForm.content === undefined || this.messageForm.content === '' ) {

                this.$message({message: '请完善信息！', type: 'warning'});
                return;
            }
            api.updateMessage(this.messageForm).then(res => {
                console.log(res)
                this.$message({message: '提交完成！', type: 'success'});
                this.messageForm = {}
                this.messageForm.siteId = this.$store.getters.siteId
            })

            // this.reload()

            // this.$refs[formName].validate((valid) => {
            // if (valid) {
            //     alert('submit!');
            // } else {
            //     console.log('error submit!!');
            //     return false;
            // }
            // });
        },

        resetForm(formName) {
            this.$refs.messageForm.resetFields()
        },

        getCode() { // 获取验证码
            commonApi.getVerify().then(res => {
                this.verifyCode = res.code
                this.codeImg = res.codeImg
            })
        },


    },
    
    // watch:{
	// 		cateTree:function(newData,oldData){
	// 			console.log("newCateTree" + newData)  //newData就是orderData
	// 			this.newCateTree = newData;     
	// 			//	methods的函数在这里调用可以获取到newOrderData的值	      
	// 			this.order();
	// 		}
    // }
		
}
</script>

<style lang="less">

.el-card {
    min-width: 380px;
    max-width: 65%;
    margin:auto;
    margin-top: 2em;
    letter-spacing:0.1em;  
    /* margin-left: 20px; */
    transition: all .5s;
    text-align: center;
}


#topText {
  font-size: 1.6em;
  /* color: #606266; */
  font-weight: 400;
  margin: auto;
  /* margin-bottom: 0.8em; */
  margin-top: 0.5em;
  margin-bottom: -0.5em;
  
  width: 60%;
}

.el-card:hover{
margin-top: 10px;
}

.el-form {
    margin-left: 5%;
}

.el-form-item__label {
    width: 20%;
  /* font-size: 12px; */
    margin-right: -10%;
}

.el-form-item .el-input {
  /* font-size: 12px; */
  width: 70%;
}


.edit-article {
    padding: 25px;
    box-shadow: 0 0 10px #e6e6e6;
}

.edit-article-header {
    font-weight: bold;
    font-size: 25px;
    margin: 0 0 25px;
    text-align: center;
}

.edit-article-footer {
    margin: 25px 25px 0;
    display: flex;
    justify-content: center;
}

.code-img {
    width: 100%;
    height: 2.85em;
    margin-right: -1em;
}



/*small xs*/
@media screen and (max-width: 638px) {
	.el-form-item__label {
        display:none;
        /* font-size: 12px; */
    }
    
	// #selectForm >>> .el-form-item__label {
    //     display:none;
    //     /* font-size: 12px; */
    // }

    .el-card {
        min-width: 380px;
        max-width: 60%;
        margin:auto;
        margin-top: 2em;
        letter-spacing:0.1em;  
        /* margin-left: 20px; */
        transition: all .5s;
    }

    #topText {
        font-size: 1.2em;
        /* color: #606266; */
        font-weight: 400;
        /* margin: auto; */
        /* margin-bottom: 0.8em; */
        margin-top: 0.5em;
        margin-left: -0.2em;
        margin-bottom: -1em;
        
        width: 60%;
    }

    #labelHidden {
        display: none;
    }
    
    #allDiv {
        text-align: center;
    }

}

</style>
